import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import memberLoungeStyle from "./member-lounge.module.css"
import { Col, Row, Container, Modal } from "react-bootstrap"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import globalStyle from "../styles/global.module.css"
import "./member-lounge.css"

const useStateWithLocalStorageForEmail = localStorageKey => {
  //get email value from localStorage
  const [emailValueFromLocalStorage, setEmailValueFromLocalStorage] = useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
      ""
  );

  //set user email value in localStorage
  useEffect(() => {
    localStorage.setItem(localStorageKey, emailValueFromLocalStorage)
  }, [emailValueFromLocalStorage]);

  return [emailValueFromLocalStorage, setEmailValueFromLocalStorage]
};

const useStateWithLocalStorageForLast4 = localStorageKey => {
  const [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage] = useState(
    (typeof window !== "undefined" && localStorage.getItem(localStorageKey)) ||
      ""
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, last4ValueFromLocalStorage)
  }, [last4ValueFromLocalStorage]);

  return [last4ValueFromLocalStorage, setLast4ValueFromLocalStorage]
};

const MemberLoungePage = ({ data }) => {

  const [emailValueFromLocalStorage] = useStateWithLocalStorageForEmail("email");
  const [last4ValueFromLocalStorage] = useStateWithLocalStorageForLast4("last4");
  const [showDiscountModal, setShowDiscountModal] = useState(false);

  useEffect(() => {
    if (
      !emailValueFromLocalStorage &&
      !last4ValueFromLocalStorage &&
      typeof window !== "undefined"
    ) {
      window.location.replace("/become-a-member")
    }
  }, [emailValueFromLocalStorage, last4ValueFromLocalStorage])

  const awsCliVideoPath = data.site.siteMetadata.awsCliVideoPath;

  const handleClose = () => setShowDiscountModal(false)
  const handleShowModal = () => setShowDiscountModal(true)

  return (
    <Layout className="site-content">
      <Container>
        <Row>
          <Col>
            <h1 className={globalStyle.entryTitle}>Member Lounge</h1>
            <h3 className={memberLoungeStyle.subtitle}>
              Welcome beautiful. Thank you for being part of Moms on Maternity.
              We appreciate all you do and we want to support you!
            </h3>
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={6} xs={12}>
            <h4 className={memberLoungeStyle.benefitsTitle}>Prenatal Yoga</h4>
            <Img fluid={data.prenatalYoga.childImageSharp.fluid}></Img>
            <ul className={memberLoungeStyle.list}>
              <li>
                <Link to={"/membership/videos/398191786"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Prenatal yoga - intro
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/392681564"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Modified flow class
                </Link>
              </li>

              <li>
                <Link to={"/membership/videos/391878169"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Modified prenatal vinyasa
                </Link>
              </li>

              <li>
                <Link to={"/membership/videos/392681680"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Prenatal for the spine yoga
                </Link>
              </li>

              <li>
                <Link to={"/membership/videos/391912137"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Easing leg cramps class
                </Link>
              </li>

              <li>
                <Link to={"/membership/videos/391912134"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Preparing for birth
                </Link>
              </li>
            </ul>
          </Col>

          <Col md={6} sm={6} xs={12}>
            <h4 className={memberLoungeStyle.benefitsTitle}>Postpartum Yoga</h4>
            <Img fluid={data.postpartumYoga.childImageSharp.fluid}></Img>

            <ul className={memberLoungeStyle.list}>
              <li>
                <Link to={"/membership/videos/398197692"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Postpartum yoga - intro
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/391912128"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Back to the mat
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/391878158"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Post caesarean
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/391878162"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Baby and me for energy and joy
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/391879016"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Mom and baby move your body
                </Link>
              </li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={6} xs={12}>
            <h4 className={memberLoungeStyle.benefitsTitle}>Mom Meditations</h4>
            <Img fluid={data.momMeditations.childImageSharp.fluid}></Img>
            <ul className={memberLoungeStyle.list}>
              <li>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-file-audio",
                    ]}
                  ></i>
                  For when you’re feeling down<br />
                  <audio controls controlsList="nodownload">
                    <source src={awsCliVideoPath.concat("mom-meditation-program1.m4a")} type="audio/mp4" />
                  </audio>
              </li>
              <li>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-file-audio",
                    ]}
                  ></i>
                  To calm your mind<br />
                  <audio controls controlsList="nodownload">
                    <source src={awsCliVideoPath.concat("mom-meditation-program2.m4a")} type="audio/mp4" />
                  </audio>
              </li>

              <li>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-file-audio",
                    ]}
                  ></i>
                  To prevent exhaustion<br />
                  <audio controls controlsList="nodownload">
                    <source src={awsCliVideoPath.concat("mom-meditation-program3.m4a")} type="audio/mp4" />
                  </audio>
              </li>
              <li>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-file-audio",
                    ]}
                  ></i>
                  For fear of the future<br />
                  <audio controls controlsList="nodownload">
                    <source src={awsCliVideoPath.concat("mom-meditation-program4.m4a")} type="audio/mp4" />
                  </audio>
              </li>
              <li>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-file-audio",
                    ]}
                  ></i>
                  To control your mood swings<br />
                  <audio controls controlsList="nodownload">
                    <source src={awsCliVideoPath.concat("mom-meditation-program5.m4a")} type="audio/mp4" />
                  </audio>
              </li>
            </ul>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <h4 className={memberLoungeStyle.benefitsTitle}>Baby Massage</h4>
            <Img fluid={data.babyMassage.childImageSharp.fluid}></Img>
            <ul className={memberLoungeStyle.list}>
              <li>
                <Link to={"/membership/videos/399455640"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Baby massage - intro
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/238484126"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Baby massage and massaging the legs & feet
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/240215570"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Massaging baby's tummy and chest & arms
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/240354840"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Massaging baby's face & back
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/240239107"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Bringing it all together
                </Link>
              </li>
              <li>
                <a
                  className={memberLoungeStyle.linkToBambinoModal}
                  onClick={handleShowModal}
                >
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon +
                        " fas fa-external-link-alt",
                    ]}
                  ></i>
                  Enjoy more courses with your 15% discount at Bambino&i
                </a>
              </li>
            </ul>
          </Col>
        </Row>

        {showDiscountModal && (
          <Row className={memberLoungeStyle.modalContent}>
            <Modal
              id="discountModal"
              className="discountModal"
              show={showDiscountModal}
              onHide={handleClose}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className={memberLoungeStyle.promoCode}>
                  <h3 className={memberLoungeStyle.discount}>
                    15% DISCOUNT CODE
                  </h3>
                  <span className={memberLoungeStyle.promoContent}>
                    for MOMS on Maternity members. Please use code:{" "}
                  </span>
                  <h3 className={memberLoungeStyle.discount}>MOM2020 on </h3>
                  <a
                    className={memberLoungeStyle.discount}
                    href={"https://bambinoandi.co.uk/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img
                      className={memberLoungeStyle.bambinoLogo}
                      fluid={data.massageLogoSmall.childImageSharp.fluid}
                    ></Img>
                  </a>
                </div>
              </Modal.Body>
            </Modal>
          </Row>
        )}

        <Row>
          <Col md={6} sm={6} xs={12}>
            <h4 className={memberLoungeStyle.benefitsTitle}>
              Expert Interviews
            </h4>
            <Img fluid={data.interviewsPhoto.childImageSharp.fluid}></Img>
            <ul className={memberLoungeStyle.list}>
              <li>
                <Link to={"/membership/videos/395426102"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Expert interviews
                </Link>
              </li>
              <li>
                <Link to={"/membership/videos/367164458"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Chiropractic care for mama and baby
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://drkatieschlein.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dr. Katie Schlein{" "}
                  </a>
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367167191"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Mindful parenting
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://debbiezeichnerlcsw.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Debbie Zeichner
                  </a>
                  , LCSW & Parent coach
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/383181651"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Maternal mental health discussion
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://www.psychedmommy.com/about"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dr. Ashurina Ream
                  </a>
                  , Psychologist
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/383326133"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Restore the core baby boot camp
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={
                      "https://www.babybootcamp.com/mommy-and-me-fitness-exercise-classes-near-me/california/los-angeles-beverly-hills-west-la-ca/"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Crystal Ferris
                  </a>
                  , Baby boot camp Beverly Hills owner
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367497956"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Baby and toddler sleep best practices
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://sugarnightnight.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Jen Varela
                  </a>
                  , Sleep coach
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367163468"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Breastfeeding tips and strategies
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://www.sdbfc.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Robin Kaplan{" "}
                  </a>
                  , Lactation consultant and owner of San Diego Breastfeeding
                  Center
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367167560"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Personal brand and fashion for the new mama
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"http://www.joseebrisebois.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {" "}
                    Josee Brisebois
                  </a>
                  , Personal fashion stylist and brand expert
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367409171"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Marriage and intimacy after baby
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with Lexi Powell, Intimacy coach{" "}
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367406621"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Raising emotionally happy kids
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://www.heartofconnecting.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Hayley Goldberg
                  </a>
                  , LMFT and Parenting coach
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367165305"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Feeding your baby, toddler and the whole family
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://nutritioninbloom.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Rachel Rothman
                  </a>
                  , Nutritionist
                </p>
              </li>

              <li>
                <Link to={"/membership/videos/367168329"}>
                  <i
                    className={[
                      memberLoungeStyle.externalLinkIcon + " fas fa-video",
                    ]}
                  ></i>
                  Prenatal and postpartum wellness and fitness
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  with
                  <a
                    className={memberLoungeStyle.linkToTheExpert}
                    href={"https://www.facebook.com/tatianamonefitness"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Tatiana Mone
                  </a>
                  , Lifestyle coach
                </p>
              </li>
            </ul>
          </Col>
          <Col md={6} sm={6} xs={12}>
            <h4 className={memberLoungeStyle.benefitsTitle}>Event Discounts</h4>
            <Img fluid={data.eventDiscounts.childImageSharp.fluid}></Img>

            <ul
              className={memberLoungeStyle.list}
              style={{ listStyleType: "none" }}
            >
              <li>
                <h4>
                  We will be publishing events and retreats for us Mama’s later
                  this year as things calm down.
                </h4>
              </li>
            </ul>
          </Col>
        </Row>

        <Row>
          <Col md={6} sm={6} xs={12}>
            <h4 className={memberLoungeStyle.benefitsTitle}>
              Health &amp; Wellness
            </h4>
            <Img fluid={data.wellnessPhoto.childImageSharp.fluid}></Img>
            <ul
              className={memberLoungeStyle.list}
              style={{ listStyleType: "none" }}
            >
              <li>
                Access your own workbook:
                <Link
                  to={"/beautiful-motherhood-guide"}
                  className={memberLoungeStyle.beautifulMotherhoodGuide}
                >
                  Beautiful Motherhood: The 6 Must Haves to Enjoy Your
                  Motherhood Journey
                </Link>
                <p className={memberLoungeStyle.listParagraph}>
                  {" "}
                  by
                  <a
                    href={"https://joymonet.com/"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={memberLoungeStyle.linkToTheExpert}
                  >
                    Joy Monet Saunders
                  </a>
                  . All MOMS on Maternity Club Members receive a complimentary
                  Exploration Call ($145 value) with Joy Monet.
                </p>
              </li>
            </ul>
          </Col>
          <Col md={6} sm={6} xs={12}></Col>
        </Row>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        awsCliVideoPath
      }
    }
    prenatalYoga: file(relativePath: { eq: "yoga/prenatal-yoga2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    postpartumYoga: file(relativePath: { eq: "yoga/postpartum-yoga2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    momMeditations: file(relativePath: { eq: "yoga/mom-meditations2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    babyMassage: file(relativePath: { eq: "massage/baby-massage2.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    wellnessPhoto: file(
      relativePath: { eq: "wellness/thiago-cerqueira-Wr3HGvx_RSM-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interviewsPhoto: file(
      relativePath: { eq: "interviews/interviews-resized.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    membershipBenefitsVideo: allVimeo(
      filter: { tags: { elemMatch: { tag: { eq: "membership-benefits" } } } }
    ) {
      edges {
        node {
          id
          name
          link
          tags {
            name
          }
          embed {
            html
          }
        }
      }
    }
    massageLogo: file(relativePath: { eq: "massage/bambino-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    yogaLogo: file(relativePath: { eq: "yoga/do-yoga-with-me-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eventDiscounts: file(
      relativePath: { eq: "discounts/event-discounts-small.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    massageLogoSmall: file(
      relativePath: { eq: "massage/bambino-logo-small.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default MemberLoungePage
